<template>
  <b-container fluid class="px-0">
    <b-row class="mt-4">
      <b-col cols="12">
        <div class="select-project d-flex">
          <b-form-select
            style="max-width: 150px !important; min-width: 100px"
            class="text-black fw-bold text-center ms-2 font-size-16"
            v-model="type"
            :options="listFilter"
            value-field="value"
            text-field="text"
          >
          </b-form-select>
        </div>
        <LineChart v-if="Chart" :chartData="Chart" />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapGetters } from 'vuex';
import LineChart from './LineChart.vue';

export default {
  components: {
    LineChart,
  },
  data() {
    return {
      gradient: null,
      gradient2: null,
      type: 'date',
      listFilter: [
        { value: 'date', text: 'Ngày' },
        { value: 'month', text: 'Tháng' },
        { value: 'year', text: 'Năm' },
      ],
    };
  },
  computed: {
    ...mapGetters({
      UserInfo: 'user/UserInfo',
      HistoryBotProfit: 'bot/HistoryBotProfit',
    }),
    Chart() {
      return this.HistoryBotProfit.data;
    },
  },
  watch: {
    type: {
      handler() {
        this.$store.dispatch('bot/req_getHistoryBotProfit', {
          type: 'dailyprofit',
          date_type: this.type,
        });
      },
    },
  },
  methods: {},
  mounted() {},
  created() {
    this.$store.dispatch('bot/req_getHistoryBotProfit', { type: 'dailyprofit' });
  },
};
</script>

<style lang="scss"></style>
